import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Mobility: Front Rack/Quad/Hamstring/Hips`}</p>
    <p>{`then,`}</p>
    <p>{`25:00 Descending AMRAP of:`}</p>
    <p>{`50-Step Ups (25/leg, 24/20″)`}</p>
    <p>{`100-Jump Rope Singles`}</p>
    <p>{`50-Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`100-Jump Rope Singles`}</p>
    <p>{`then,`}</p>
    <p>{`40/80/40/80`}</p>
    <p>{`30/60/30/60`}</p>
    <p>{`20/40/20/40`}</p>
    <p>{`10/20/10/20`}</p>
    <p><em parentName="p">{`If you complete this set start back at 50/100/50/100.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      